import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },

  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('@/views/dashboard/Dashboard.vue'),
  // },
  {
    path: '/mainpage',
    name: 'mainpage',
    component: () => import('@/views/mainpage/Mainpage.vue'),
  },
  {
    path: '/content',
    name: 'content',
    component: () => import('@/views/content/Content.vue'),
  },
  {
    path: '/contentgroup',
    name: 'contentgroup',
    component: () => import('@/views/content/Contentgroup.vue'),
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/content/Tags.vue'),
  },
  {
    path: '/howto',
    name: 'howto',
    component: () => import('@/views/howto/Howto.vue'),
  },
  {
    path: '/dns',
    name: 'dns',
    component: () => import('@/views/dns/Dns.vue'),
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('@/views/page/Page.vue'),
  },

  // {
  //   path: '/typography',
  //   name: 'typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  // },
  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
  // {
  //   path: '/pages/account-settings',
  //   name: 'pages-account-settings',
  //   component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  // },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
