import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import preset from './default-preset/preset'

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg',
})

export default vuetify

// export default new Vuetify({

// })
